import './App.css';
import uitoolkit from "@zoom/videosdk-ui-toolkit";
import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css';
import KJUR from 'jsrsasign';
import { db } from './firebaseConfig';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function App() {
  const [isHost, setIsHost] = useState(false);
  const [currentRoom, setCurrentRoom] = useState('waiting');
  const [userName, setUserName] = useState('');
  const [meetingData, setMeetingData] = useState({ topic: '', password: '', name: '' });

  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const name = query.get('name');
    const password = query.get('password');
    const topic = query.get('topic');

    if (name && password && topic) {
      setMeetingData({ name, password, topic });
      setUserName(name);
    }
  }, [location]);

  const zoomConfig = {
    sdkKey: process.env.REACT_APP_ZOOM_SDK_KEY,
    sdkSecret: process.env.REACT_APP_ZOOM_SDK_SECRET,
    topic: meetingData.topic,
    name: meetingData.name,
    password: meetingData.password,
  };
  console.log('SDK Key:', zoomConfig.sdkKey);
  console.log('SDK Secret:', zoomConfig.sdkSecret);

  var role = isHost ? 1 : 0;

  function generateSignature(sdkKey, sdkSecret, sessionName, role, sessionKey, userIdentity) {
    const iat = Math.round(new Date().getTime() / 1000) - 30;
    const exp = iat + 60 * 60 * 2;
    const oHeader = { alg: 'HS256', typ: 'JWT' };

    const oPayload = {
      app_key: sdkKey,
      tpc: sessionName,
      role_type: role,
      session_key: sessionKey,
      user_identity: userIdentity,
      version: 1,
      iat: iat,
      exp: exp
    };

    const sHeader = JSON.stringify(oHeader);
    const sPayload = JSON.stringify(oPayload);
    const sdkJWT = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, sdkSecret);
    return sdkJWT;
  }

  function getVideoSDKJWT(sessionName, sessionPassword, userName) {
    let token = generateSignature(zoomConfig.sdkKey, zoomConfig.sdkSecret, sessionName, role, sessionPassword, userName);
    console.log("Token:", token);
    return {
      videoSDKJWT: token,
      sessionName: sessionName,
      userName: userName,
      sessionPasscode: sessionPassword,
      features: ['video', 'audio', 'settings', 'users', 'chat', 'share']
    };
  }

  function joinSession(sessionName, sessionPassword, userName) {
    let completeConfig = getVideoSDKJWT(sessionName, sessionPassword, userName);
    var sessionContainer = document.getElementById('sessionContainer');
    document.getElementById('join-flow').style.display = 'none';

    uitoolkit.joinSession(sessionContainer, completeConfig);
    uitoolkit.onSessionClosed(sessionClosed);

    logAttendance(userName, sessionName, 'joined');
  }

  function sessionClosed() {
    var sessionContainer = document.getElementById('sessionContainer');
    uitoolkit.closeSession(sessionContainer);
    document.getElementById('join-flow').style.display = 'block';

    logAttendance(userName, meetingData.topic, 'left');
  }

  function logAttendance(userName, sessionName, action) {
    const timestamp = new Date().toISOString();
    db.collection('attendance').add({
      userName,
      sessionName,
      action,
      timestamp
    });
  }

  return (
    <div className="App">
      <main>
        <div id="join-flow">
          <h1>Zoom Video SDK Sample React</h1>
          <p>User interface offered by the Video SDK UI Toolkit</p>
          <input
            type="text"
            placeholder="Enter your name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          <button onClick={() => joinSession(meetingData.topic, meetingData.password, userName)}>Join Main Session</button>
          {isHost && (
            <>
              <button onClick={() => joinSession('Interview Room', 'Interview', userName)}>Join Interview Room</button>
              <button onClick={() => joinSession('Waiting Room', 'Waiting', userName)}>Join Waiting Room</button>
            </>
          )}
        </div>

        <div id='sessionContainer'></div>
      </main>
    </div>
  );
}

export default App;